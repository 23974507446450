import axios from "axios";


/**
 * ✅ Get from Local Storage (Removes Expired Entries)
 */
export const getFromLocalStorage = (key) => {
    const cachedData = localStorage.getItem(key);
    if (!cachedData) return null;

    const { value, expiry } = JSON.parse(cachedData);
    if (Date.now() > expiry) {
        localStorage.removeItem(key); // Remove expired cache
        return null;
    }
    return value;
};

/**
 * ✅ Fetch Cached Location from Backend (Redis/MySQL)
 */
export const fetchFromBackendCache = async (key, type, experimentValue = '') => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/cache`, { params: { type, key, experimentValue } });
        return response.data || null;
    } catch (error) {
        console.error(`❌ Backend cache fetch error for ${type}:`, error);
        return null;
    }
};


/**
 * ✅ Fetch Cached Location from Backend (Redis/MySQL)
 */
export const fetchTimezoneFromBackendCache = async (latitude, longitude) => {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/cache/timeZone`, { params: { latitude, longitude } });
      return response.data || null;
  } catch (error) {
      console.error(`❌ Backend cache fetch error for ${latitude},${longitude}:`, error);
      return null;
  }
};


/**
 * ✅ Fetch Cached Location from Backend (Redis/MySQL)
 */
export const fetchAddressFromLatLongBackendCache = async (latitude, longitude, language = 'en') => {
  try {
      const response = await axios.get(`${process.env.REACT_APP_API_SERVER_URL}/api/cache/addressDetails`, {
          params: { latitude, longitude, language },
      });

      if (response.status === 200 && response.data && !response.data.error) {
          return response.data;
      }

      console.warn(`⚠️ Backend cache miss for lat: ${latitude}, lng: ${longitude}`);
      return null;
  } catch (error) {
      console.error(`❌ Backend cache fetch error for lat: ${latitude}, lng: ${longitude}`, error);
      return null;
  }
};
/**
 * ✅ Cache Place Details (Google Places API)
 */
export async function getCachedPlaceDetails(placeId, experiment_value) {
    // ✅ Check Backend Cache (Redis/MySQL)
    const backendCache = await fetchFromBackendCache(placeId, "placeDetails", experiment_value);
    if (backendCache) return backendCache;
    return null;
}

/**
 * ✅ Cache TimeZone Data (Google Timezone API)
 */
export async function getCachedTimeZone(location) {
    const key = `${location.lat}-${location.lng}`;

    // ✅ Check Backend Cache (Redis/MySQL)
    const backendCache = await fetchTimezoneFromBackendCache(location.lat, location.lng);
    if (backendCache) return backendCache;

    return null;
}
