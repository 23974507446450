import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { fetchCurrentUser, sendVerificationEmail } from '../../ducks/user.duck';
import {
  Footer,
  LayoutSideNavigation,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  Page,
  UserNav,
} from '../../components';
import { AddressDetailsForm, ContactDetailsForm, PersonalDetailsForm } from '../../forms';
import { TopbarContainer } from '../../containers';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { saveContactDetails, saveContactDetailsClear } from './ContactDetailsPage.duck';
import css from './ContactDetailsPage.css';
import { getConnectCalendarTabStatus } from '../../ducks/TabPanels.duck';
import { pushGTMGuestVerify } from '../../util/gtm/gtmHelpers';
import { EVENT_SAVED_CONTACT_INFORMATION } from '../../util/gtm/gtmConstants';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { savePersonalDetails } from '../PersonalDetailsPage/PersonalDetailsPage.duck';
import { saveAddressDetails } from '../AddressDetailsPage/AddressDetailsPage.duck';

const { LatLng } = sdkTypes;

export const ContactDetailsPageComponent = props => {
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    contactDetailsChanged,
    onChange,
    scrollingDisabled,
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    onResendVerificationEmail,
    onSubmitContactDetails,
    onSubmitAgeDetailsForm,
    onSubmitAddressDetailsForm,
    intl,
    saveAddressDetailsSuccess,
    saveAddressDetailsError,
    saveAddressDetailsInProgress,
    connectCalendarTabVisibility,
    fetchCalendarVisibility,
  } = props;

  const [eventData, setEventData] = useState(null);
  const [analyticsTriggered, setAnalyticsTriggered] = useState(false);
  const [disableAddress,setDisableAddress]= useState(false)

  useEffect(() => {
    fetchCalendarVisibility(); // Fetch calendar visibility on component mount
  }, []); // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    const fetchDataAndTriggerAnalytics = async () => {
      try {
        // Fetch events data
        const response = await getEventsByBucket('browsing');
        setEventData(response); // Store response in state

        // Trigger analytics event if currentUser is available and event has not been triggered yet
        if (currentUser && currentUser.id && !analyticsTriggered) {
          // console.log('Analytics triggered event');
          triggerAnalyticsEvent({
            event_id: event_trigger_ids.PROFILE_VIEW_CONTACT_INFORMATION,
            eventData: response.data,
            props: {
              ui: {
                button: 'Contact info',
                
              },
            },
            userId: currentUser.id.uuid,
            user: currentUser,
          });
          setAnalyticsTriggered(true); // Mark analytics event as triggered
        }
      } catch (error) {
        console.error('Error triggering analytics event:', error);
      }
    };

    // Only fetch and trigger analytics if currentUser changes or if analyticsTriggered changes to false
    if (currentUser && !analyticsTriggered) {
      fetchDataAndTriggerAnalytics();
    }
  }, [currentUser, analyticsTriggered]); // Dependency array ensures this effect runs when currentUser or analyticsTriggered changes

  const user = ensureCurrentUser(currentUser);

  let tabs = [...config.custom.tabs];
  tabs = tabs
    .map(tab => ({ ...tab }))
    .filter(tab => {
      if (tab.linkProps.name === 'SyncCalenderPage') {
        return Boolean(connectCalendarTabVisibility);
      }
      return true;
    });
  const currentTab = tabs.find(tab => tab.linkProps.name === 'ContactDetailsPage');
  if (currentTab) {
    currentTab.selected = true;
    // console.log("Current Tab Selected: ", currentTab);
  }

  const currentEmail = user.attributes.email || '';
  const protectedData = user.attributes.profile.protectedData || {};

  const { dateOfBirth, blockNo,
    location,
    building,
    floorUnit,
    city,
    country,
    postalCode,
    neighborhood, } = protectedData;

    const { selectedPlace } = location || {};
    const { address, origin } = selectedPlace || {};

  const initialDobValues = {
    dateOfBirth,
  };

useEffect(()=>{
  if( 
    building ||
    floorUnit ||
    city ||
    country ||
    postalCode ||
    neighborhood || address || origin ){
      setDisableAddress(true)
    }else{
    setDisableAddress(false)

    }
},[protectedData])

  const initialAddressValues = {
    blockNo,
    location: {
      search: address,
      selectedPlace: {
        address,
        origin: new LatLng(origin && origin.lat, origin && origin.lng),
      },
    },
    neighborhood,
    building,
    floorUnit,
    city,
    country,
    postalCode,
  };

  const currentPhoneNumber = protectedData ? protectedData.phoneNumber : '';
  const currentPhoneCode = protectedData.phoneObj ? protectedData.phoneObj.phoneCode : '';
  const contactInfoForm = user.id ? (
    <ContactDetailsForm
      className={css.form}
      initialValues={{
        email: currentEmail,
        phoneNumber: currentPhoneNumber && currentPhoneNumber.replace('+65', ''),
        phoneCode: currentPhoneCode,
      }}
      saveEmailError={saveEmailError}
      savePhoneNumberError={savePhoneNumberError}
      currentUser={currentUser}
      onResendVerificationEmail={onResendVerificationEmail}
      onSubmit={values => {
        // pushGTMGuestVerify({
        //   props: props,
        //   event: EVENT_SAVED_CONTACT_INFORMATION,
        // });
        onSubmitContactDetails({ ...values, currentEmail, currentPhoneNumber, currentPhoneCode });
      }}
      onChange={onChange}
      inProgress={saveContactDetailsInProgress}
      ready={contactDetailsChanged}
      sendVerificationEmailInProgress={sendVerificationEmailInProgress}
      sendVerificationEmailError={sendVerificationEmailError}
    />
  ) : null;


    const addressInfoForm = user.id ? (
      <AddressDetailsForm
        className={css.form}
        initialValues={initialAddressValues}
        currentUser={currentUser}
        inProgress={saveAddressDetailsInProgress}
        ready={saveAddressDetailsSuccess}
        saveAddressDetailsError={saveAddressDetailsError}
        shouldHideSubmitButton={disableAddress}
        onSubmit={values => {
          const {
            blockNo,
            location,
            building,
            floorUnit,
            city,
            country,
            postalCode,
            neighborhood,
          } = values;
          const {
            selectedPlace: { address, origin },
          } = location;
          const updatedValues = {
            blockNo,
            location: {
              search: address,
              selectedPlace: {
                address,
                origin: new LatLng(origin.lat, origin.lng),
              },
              neighborhood,
              building,
              floorUnit,
            },
            neighborhood,
            building,
            floorUnit,
            city,
            country,
            postalCode,
          };
          onSubmitAddressDetailsForm(updatedValues);
          // const properties = createRawPropertiesForGTM({
          //   props,
          // });
          // pushGTMGuestVerify({
          //   props: this.props,
          //   event: EVENT_SAVED_ADDRESS_DETAILS,
          // });
          // createConversionEvents(
          //   properties,
          //   EVENT_CONVERSION_CREATE_ACCOUNT_DRIVER_CREDENTIALS,
          //   'click'
          // );
        }}
      />
    ) : null;


   const dobForm = user.id ? (
      <PersonalDetailsForm
        className={css.form}
        initialValues={initialDobValues}
        currentUser={currentUser}
        inProgress={saveAddressDetailsInProgress}
        ready={saveAddressDetailsSuccess}
        saveAddressDetailsError={saveAddressDetailsError}
        onSubmit={values => {
          const { dateOfBirth } = values;
          const updatedValues = {
            dateOfBirth,
          };
          // pushGTMGuestVerify({
          //   props: this.props,
          //   event: EVENT_GUEST_VERIFY_ADD_DOB,
          // });
          onSubmitAgeDetailsForm(updatedValues);
        }}
      />
    ) : null;

  const title = intl.formatMessage({ id: 'ContactDetailsPage.title' });

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            currentPage="ContactDetailsPage"
            desktopClassName={css.desktopTopbar}
            mobileClassName={css.mobileTopbar}
          />
          <UserNav selectedPageName="ContactDetailsPage" />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav tabs={tabs} user={user} />
        <LayoutWrapperMain>
          <div className={css.content}>
            <h1 className={css.title}>
              <FormattedMessage id="ContactDetailsPage.heading" />
            </h1>
            {contactInfoForm}

            <p className={css.warningMessage} >*Details below cannot be edited after verification is complete. For any changes, please contact customer support.</p>
            <h1 className={css.title}>
              <FormattedMessage id="AddressDetailsPage.heading" />
            </h1>
            {addressInfoForm}

            <h1 className={css.ageDetailsTitle}>
              <FormattedMessage id="AgeDetailsPage.heading" />
            </h1>
            {dobForm}
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

ContactDetailsPageComponent.defaultProps = {
  saveEmailError: null,
  savePhoneNumberError: null,
  currentUser: null,
  sendVerificationEmailError: null,
};

const { bool, func } = PropTypes;

ContactDetailsPageComponent.propTypes = {
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  saveContactDetailsInProgress: bool.isRequired,
  currentUser: propTypes.currentUser,
  contactDetailsChanged: bool.isRequired,
  onChange: func.isRequired,
  onSubmitContactDetails: func.isRequired,
  onSubmitAgeDetailsForm:func.isRequired,
  onSubmitAddressDetailsForm:func.isRequired,
  scrollingDisabled: bool.isRequired,
  sendVerificationEmailInProgress: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  onResendVerificationEmail: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, sendVerificationEmailInProgress, sendVerificationEmailError } = state.user;
  const { connectCalendarTabVisibility } = state.tabPanels;
  const {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    contactDetailsChanged,
  } = state.ContactDetailsPage;
  return {
    saveEmailError,
    savePhoneNumberError,
    saveContactDetailsInProgress,
    currentUser,
    contactDetailsChanged,
    scrollingDisabled: isScrollingDisabled(state),
    sendVerificationEmailInProgress,
    sendVerificationEmailError,
    connectCalendarTabVisibility,
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmitAddressDetailsForm: values => dispatch(saveAddressDetails(values)),
  onSubmitAgeDetailsForm: values => dispatch(savePersonalDetails(values)),
  onChange: () => dispatch(saveContactDetailsClear()),
  onResendVerificationEmail: () => dispatch(sendVerificationEmail()),
  onSubmitContactDetails: values => dispatch(saveContactDetails(values)),
  fetchCalendarVisibility: () => dispatch(getConnectCalendarTabStatus()),
});

const ContactDetailsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(ContactDetailsPageComponent);

ContactDetailsPage.loadData = () => {
  return fetchCurrentUser(); // Fetch current user data when loading this page
};

export default ContactDetailsPage;
