import React, { Component } from 'react';
import moment from 'moment';
import { string } from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import {
  ExternalLink,
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  NamedLink,
} from '../../components';
import { pushGTMBrowseEvent } from '../../util/gtm/gtmHelpers';
import {
  EVENT_BROWSE_ABOUTUS,
  EVENT_BROWSE_FAQ,
  EVENT_BROWSE_HOWITWORKS,
  EVENT_BROWSE_POLICIES,
  EVENT_BROWSE_RENTACAR,
  EVENT_BROWSE_SHAREACAR,
  EVENT_BROWSE_TRUSTANDSAFE,
} from '../../util/gtm/gtmConstants';
import LogoImage from '../../assets/logos/logo-footer@200.png';
import css from './Footer.css';
import SeoLandingRentalCategories from '../../containers/SeoLandingPage/SeoLandingRentalCategories';
import { triggerAnalyticsEvent } from '../../util/amplitudeMapEvents';
import { event_trigger_ids } from '../../util/analyticsConstants';
import { getEventsByBucket } from '../../util/gtm/gtmCreateProperties';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventData: null,
    };
    // console.log("footer props", props);
  }

  async componentDidMount() {
    try {
      const response = await getEventsByBucket('browsing');
      this.setState({ eventData: response.data });
    } catch (error) {
      console.error('Error fetching event data:', error);
    }
  }

  renderSocialMediaLinks = intl => {
    const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
    const siteTwitterPage = twitterPageURL(siteTwitterHandle);

    const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
    const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
    const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

    const fbLink = siteFacebookPage ? (
      <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
        <IconSocialMediaFacebook />
      </ExternalLink>
    ) : null;

    const twitterLink = siteTwitterPage ? (
      <ExternalLink
        key="linkToTwitter"
        href={siteTwitterPage}
        className={css.icon}
        title={goToTwitter}
      >
        <IconSocialMediaTwitter />
      </ExternalLink>
    ) : null;

    const instragramLink = siteInstagramPage ? (
      <ExternalLink
        key="linkToInstagram"
        href={siteInstagramPage}
        className={css.icon}
        title={goToInsta}
      >
        <IconSocialMediaInstagram />
      </ExternalLink>
    ) : null;
    return [fbLink, twitterLink, instragramLink].filter(v => v != null);
  };

  handleLinkClick = (eventId, label) => {
    console.log("Ahdnle first link clicked")
    const { currentUser } = this.props;
    const { eventData } = this.state;

    triggerAnalyticsEvent({
      event_id: eventId,
      eventData,
      props: {
        ui: {
          button: label,
          page: 'ProfileScreen'
        },
      },
      userId: currentUser && currentUser.id ? currentUser.id.uuid : null,
      user: currentUser
    });
  };

  render() {
    const { rootClassName, className, intl, bookingPanelMobileHeight = 0 } = this.props;
    const socialMediaLinks = this.renderSocialMediaLinks(intl);
    const classes = classNames(rootClassName || css.root, className);
    const pushGTMBrowse = event => {
      pushGTMBrowseEvent({
        props: this.props,
        event,
      });
    };
    const currentYear = moment(new Date()).format('YYYY');

    return (
      <div className={classes}>
        <SeoLandingRentalCategories />
        <div className={css.topBorderWrapper}>
          <div className={css.content}>
            <div className={css.someLiksMobile}>{socialMediaLinks}</div>
            <div className={css.links}>
              <div className={css.organization} id="organization">
                <NamedLink name="LandingPage" className={css.logoLink}>
                  <Logo format="desktop" className={css.logo} logoSrc={LogoImage} />
                </NamedLink>
                <div className={css.organizationInfo}>
                  <p className={css.organizationDescription}>
                    <FormattedMessage id="Footer.organizationDescription" />
                  </p>
                  <p className={css.FooterAddress}>
                    <FormattedMessage id="Footer.Address" />
                  </p>
                  <p className={css.organizationCopyright}>
                    <NamedLink name="LandingPage" className={css.copyrightLink}>
                      <FormattedMessage id="Footer.copyright" values={{ currentYear }} />
                    </NamedLink>
                  </p>
                </div>
              </div>
              <div className={css.searches}>
                <ul className={css.list}>
                  <li className={css.listItem}></li>
                </ul>
              </div>
              <div className={css.infoLinks}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink
                      name="BecomeAGuestPage"
                      className={css.link}
                      // onClick={() => pushGTMBrowse(EVENT_BROWSE_RENTACAR)}
                    >
                      <FormattedMessage id="Footer.rentACar" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="BecomeAHostPage"
                      className={css.link}
                      // onClick={() => pushGTMBrowse(EVENT_BROWSE_SHAREACAR)}
                    >
                      <FormattedMessage id="Footer.shareYourCar" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="HowItWorkPage"
                      className={css.link}
                      onClick={() =>  this.handleLinkClick(event_trigger_ids.BROWSE_CLICK_HOW_IT_WORKS, "How it works")}
                    >
                      <FormattedMessage id="Footer.howItWork" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="TrustAndSafetyPage"
                      className={css.link}
                      // onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
                    >
                      <FormattedMessage id="Footer.trustAndSafety" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink
                      name="LandingPageGo"
                      className={css.link}
                      // onClick={() => pushGTMBrowse(EVENT_BROWSE_TRUSTANDSAFE)}
                    >
                      Drive lah Go™
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="InstantBookingLandingPage" className={css.link}>
                      Instant Booking
                    </NamedLink>
                  </li>
                </ul>
              </div>
              <div className={css.searchesExtra}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <NamedLink
                      name="AboutPage"
                      className={css.link}
                      // onClick={() => pushGTMBrowse(EVENT_BROWSE_ABOUTUS)}
                    >
                      <FormattedMessage id="Footer.aboutUs" />
                    </NamedLink>
                  </li>
                  <li
                    className={css.listItem}
                    onClick={() =>
                      this.handleLinkClick(event_trigger_ids.BROWSE_CLICK_POLICIES, "Our Policies")
                    }
                  >
                    <ExternalLink
                      href={'https://help.drivelah.sg/en/collections/1614888-our-policies'}
                      className={css.link}
                    >
                      <FormattedMessage id="Footer.policies" />
                    </ExternalLink>
                  </li>
                  <li
                    className={css.listItem}
                    onClick={() =>
                      this.handleLinkClick(event_trigger_ids.BROWSE_CLICK_FAQ, "F.A.Q.")
                    }
                  >
                    <ExternalLink href={'https://help.drivelah.sg/en/collections/1614883-faq'} className={css.link}>
                      <FormattedMessage id="Footer.faq" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink
                      href={'https://help.drivelah.sg'}
                      className={css.link}
                      onClick={() =>
                        this.handleLinkClick(event_trigger_ids.BROWSE_CLICK_NEED_HELP, "Help")
                      }
                    >
                      <FormattedMessage id="Footer.help" />
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="ContactUsPage" className={css.link}>
                      <FormattedMessage id="Footer.contactUs" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="Career" className={css.link}>
                      <FormattedMessage id="Footer.career" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink className={css.link} href="https://www.drivelah.sg/blog/">
                      Blog
                    </ExternalLink>
                  </li>
                </ul>
              </div>
              <div className={css.searchesExtra}>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <div className={css.ourPartners}>Our partners</div>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={'https://www.tokiomarine.com/'} className={css.link}>
                      Tokio Marine
                    </ExternalLink>
                  </li>
                  <li className={css.listItem}>
                    <ExternalLink href={'https://www.income.com.sg/'} className={css.link}>
                      NTUC Income
                    </ExternalLink>
                  </li>
                  {/*<li className={css.listItem}>*/}
                  {/*  <ExternalLink href={'https://ucars.sg/'} className={css.link}>*/}
                  {/*    Ucars*/}
                  {/*  </ExternalLink>*/}
                  {/*</li>*/}
                </ul>
              </div>
              <div className={css.extraLinks}>
                <div className={css.someLinks}>{socialMediaLinks}</div>
                <div className={css.legalMatters}>
                  <ul className={css.tosAndPrivacy}>
                    <li>
                      <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                        <FormattedMessage id="Footer.termsOfUse" />
                      </NamedLink>
                    </li>
                    <li>
                      <NamedLink name="PrivacyPolicyPage" className={css.privacy} 
                      onClick={() =>
                        this.handleLinkClick(event_trigger_ids.BROWSE_VIEW_PRIVACY_POLICY, 'Privacy policy')
                      }
                      >
                        <FormattedMessage id="Footer.privacyPolicy" />
                      </NamedLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              className={css.copyrightAndTermsMobile}
              style={
                bookingPanelMobileHeight
                  ? { marginBottom: (bookingPanelMobileHeight * 40) / 100 }
                  : {}
              }
            >
              <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
                <FormattedMessage id="Footer.copyright" values={{ currentYear }} />
              </NamedLink>
              <div className={css.tosAndPrivacyMobile}>
                <NamedLink name="RentalAgreementPage" className={css.rental}>
                  <FormattedMessage id="Footer.rentalAgreement" />
                </NamedLink>
                <NamedLink name="TermsOfServicePage" className={css.terms}>
                  <FormattedMessage id="Footer.terms" />
                </NamedLink>
                <NamedLink
                  name="PrivacyPolicyPage"
                  className={css.privacy}
                  onClick={() =>
                    this.handleLinkClick(event_trigger_ids.BROWSE_VIEW_PRIVACY_POLICY, 'Privacy policy')
                  }
                >
                  <FormattedMessage id="Footer.privacyPolicy" />
                </NamedLink>
              </div>
            </div>
            <div>
              <p className={css.FooterAddressMobile}>
                <FormattedMessage id="Footer.Address" />
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => ({
  currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(injectIntl(Footer));
